<template>
  <v-app>
  <div class="login-frame">

    <div class="login-form">
      <router-link to="/" class="links">
      <v-img max-height="64" max-width="172" class="logo" style="margin-bottom: 20px;"
             v-bind:src="require('@/assets/images/Leita_blue.png')">
      </v-img>
      </router-link>

        <v-img max-height="80" max-width="80" class="logo2"
               v-bind:src="require('@/assets/images/success.png')">
        </v-img>


      <v-row style="padding-bottom: 15px; padding-top: 20px;">
        <span v-if="$route.params.type==='recovery_password'" class="succsess-text1">Password has been reset!</span>
        <span v-if="$route.params.type==='registration'" class="succsess-text1">Registration successful!</span>

      </v-row>

      <v-row style="margin-top: 0px; margin-bottom: 20px;">
        <span class="succsess-text1">Please check your E-mail</span>

      </v-row>

      <v-container>
        <v-row style="padding-bottom: 8px;">
          <v-col cols="2"></v-col>
          <v-col cols="8" class="d-flex justify-center"><router-link to="/login" class="links">Back to login</router-link>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>


      </v-container>

    </div>


  </div>
    <Footer></Footer>
  </v-app>
</template>

<script>

import Footer from "@/components/home/Footer";

export default {
  name: "CheckEmail",
  components: {Footer},
  mounted() {
    console.log(this.$route.params.type);
  }
}
</script>

<style scoped src="@/assets/css/login.css">
</style>
